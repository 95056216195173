<template>
  <v-container grid-list-xl>
    <v-row>
      <v-col cols="12" sm="12" md="6" lg="6">
        <v-row>
          <!-- Benachrichtigungen  -->
          <div>
            <h2 v-html="$t('profile_notifications')"></h2>
            <div>
              <!--              v-model="applicationUser.emailNotificationIssues"-->
              <div>
                <v-switch color="primary" :label="$t('profile_notifications_new_issue')" id="issue_item_done" v-model="emailNotificationIssues"></v-switch>
                <v-switch color="primary" :label="$t('profile_notifications_new_contract')"></v-switch>
                <v-switch color="primary" :label="$t('profile_notifications_via_mail')"></v-switch>
                <h2 v-html="$t('profile_notifications_automatic')"></h2>
                <v-switch color="primary" :label="$t('profile_notifications_single_result')"></v-switch>
                <v-switch color="primary" :label="$t('profile_notifications_single_result_with_error')"></v-switch>
                <v-switch color="primary" :label="$t('profile_notifications_monthly_result')"></v-switch>
                <v-switch color="primary" :label="$t('profile_notifications_sammelmilch_result')"></v-switch>
                <v-switch color="primary" :label="$t('profile_notifications_additional_result')"></v-switch>
              </div>
            </div>
          </div>

          <!-- ENTWURF - Weitere Einstellungen-->
          <div class="top-margin">
            <h2>Beta - Weitere Einstellungen</h2>
            <div>Wählen Sie, ob ...</div>
            <div>
              <v-switch color="primary" :label="$t('tbd Automatische Bestellung aktiv')"></v-switch>
              <div></div>
            </div>
          </div>
        </v-row>
      </v-col>
      <v-col cols="12" sm="12" md="6" lg="6">
        <!-- Freigabe Milchprüfungsresultate-->
        Zweite Spalte alte Version
        <div v-if="$getBeta()" class="top-margin beta">
          <farm-accesses />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script lang="ts">
import FarmAccesses from '@/components/farmAccesses.vue'
import { mapGetters } from 'vuex'
import { defineComponent } from 'vue'
import { useLanguagesStore } from '@/store/enums/LanguagesStore'

export default defineComponent({
  name: 'userProfile',
  props: { emailNotificationIssues: { type: Object | String, required: true } },
  data() {
    return {}
  },
  computed: {
    languages() {
      useLanguagesStore().languages
    }
  },
  components: {
    FarmAccesses: FarmAccesses
  }
})
</script>
<style lang="scss" scoped>
@import '@/styles/variables.scss';

.top-margin {
  margin-top: $spacer-md;
}
</style>
