<template>
  <v-container grid-list-xl v-if="ready">
    <v-row>
      <v-col cols="12" sm="12" md="6" lg="6">
        <v-row>
          <!-- Agate-ID -->
          <div class="top-margin">
            <h2 v-html="$t('profile_agateId')"></h2>
            <div>
              <v-text-field v-model="localUserData.agateId" :label="$t('profile_agateId')" :placeholder="$t('profile_agateId')" disabled></v-text-field>
            </div>
          </div>

          <!-- Email -->
          <div class="top-margin">
            <h2 v-html="$t('profile_email')"></h2>
            <p v-html="$t('profile_changeEmail')"></p>
            <div>
              <v-text-field v-model="localUserData.email" :label="$t('profile_email')" :placeholder="$t('profile_email')" disabled></v-text-field>
            </div>
          </div>

          <!-- Kennwort -->
          <div class="top-margin">
            <h2 v-html="$t('profile_password')"></h2>
            <div v-html="$t('profile_changePassword')"></div>
            <div>
              <v-alert border="top" colored-border type="info" elevation="2">
                <span v-html="$t('profile_changePassword_contact') + ' '"></span>
                <a href="tel:0848 222 400">0848 222 400</a>
                <span v-html="$t('profile_or')"></span>
                <a href="mailto:info@agatehelpdesk.ch"> info@agatehelpdesk.ch</a>
              </v-alert>
            </div>
          </div>

          <!-- Infos -->
          <div class="top-margin">
            <h2 v-html="$t('profile_infos')"></h2>
            <v-text-field
              :label="$t('last_login')"
              :value="$formatChDateTime(localUserData.lastLogin)"
              readonly
              :placeholder="$t('Letzes Login')"
            ></v-text-field>
          </div>
        </v-row>
      </v-col>
      <v-col cols="12" sm="12" md="6" lg="6">
        <v-row class="row spacer-md beta" v-if="$getBeta()">
          <!-- Persönliche Daten aus Agate -->
          <h2 v-html="$t('profile_agate_personal_data')"></h2>
          TODO
          <v-textarea filled rows="5" id="profile_agate_personal_data" v-model="localUserData.personalData"></v-textarea>
          <!-- Sprache -->
          TODO
          <v-select
            id="profile_agate_language"
            disabled
            v-model="localUserData.language"
            :label="$t('profile_agate_language')"
            :items="languages"
            :item-title="this.$getLangKey()"
            item-value="id"
            clearable
            @keyup.enter="search"
          />
          <!-- Telefon -->
          TODO
          <v-text-field v-model="localUserData.phone1" disabled type="phone" id="profile_agate_phone" :label="$t('profile_agate_phone')" />
          <!-- Mobile -->
          TODO
          <v-text-field v-model="localUserData.phone2" disabled type="phone" id="profile_agate_mobile" :label="$t('profile_agate_mobile')" />
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import { useLanguagesStore } from '@/store/enums/LanguagesStore'

export default defineComponent({
  name: 'user',
  props: { modelValue: { type: Object, required: true } },
  data() {
    return {}
  },
  computed: {
    languages() {
      useLanguagesStore().languages
    },
    ready(): Boolean {
      return typeof this.modelValue == 'object'
    },
    localUserData: {
      get(): any {
        return this.modelValue
      },
      set(): void {
        //emit to parent if needed
      }
    }
  }
})
</script>
<style lang="css"></style>
