<template>
  <div>
    <h2 v-html="$t('profile_milk_quality_results_release')"></h2>
    <div><span v-html="$t('profile_milk_quality_results_release_text')" /></div>
    <div v-if="ownedFarmAccesses.length > 0" class="top-margin">
      <v-data-table-server
        :loading="loading"
        :headers="ownedFarmAccessesFields"
        :items="ownedFarmAccesses"
        :hide-default-footer="true"
        disable-pagination
        hover
        class="no-margin-top"
      >
        <template v-slot:[`item.edit`]="{ item }">
          <span @click="changeFarmAccess(item)" v-if="!checkIfStatusCanBeChanged(item.status)" class="edit-farm-access">
            <v-icon dbmblueprimary>mdi-text-box-edit-outline</v-icon>
          </span>
          <span v-if="checkIfStatusCanBeChanged(item.status)">
            <v-icon dbmpassivgrey>mdi-text-box-edit-outline</v-icon>
          </span>
        </template>
        <template v-slot:[`item.collector`]="{ item }">
          <div v-if="item.legalEntityOwner">{{ item.legalEntityOwner.name1 }} {{ item.legalEntityOwner.name2 }}</div>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          {{ showStatus(item.status) }}
        </template>
        <template v-slot:[`item.datetime`]="{ item }"> {{ $formatChDate(item.validFrom) }}</template>
        <template v-slot:[`item.validuntil`]="{ item }"> {{ $formatChDate(item.validUntil) }}</template>
      </v-data-table-server>
    </div>
    <div>
      <a href class="link-left" @click.prevent="getInfos()" v-html="$t('profile_milk_quality_results_release_table_link_to_infos')"></a>
    </div>
    <v-dialog v-model="dialog" max-width="800px">
      <v-form ref="farmAccessesForm" @submit.prevent="" id="farmAccessesForm">
        <v-card>
          <v-card-title>
            <span v-html="$t('farm_access_header_profile_data_release')" />
          </v-card-title>
          <v-card-text>
            <h2 v-html="$t('farm_access_edit_status_description')"></h2>

            <v-select
              v-if="checkIfStatusCanBeChanged(farmAccessToChange.status)"
              :disabled="checkIfStatusCanBeChanged(farmAccessToChange.status)"
              v-model="farmAccessToChange.status"
              :items="farmAccessStatuses"
              :item-title="this.$getLangKey()"
              item-value="name"
              id="profile-farmer-access"
              :label="$t('farm_access_header_profile_decision')"
              @click="click()"
              @change="change()"
            />
            <v-select
              v-if="!checkIfStatusCanBeChanged(farmAccessToChange.status)"
              v-model="farmAccessToChange.status"
              :items="limitedFarmAccessStatuses"
              :item-title="this.$getLangKey()"
              item-value="name"
              id="profile-farmer-access"
              :label="$t('farm_access_header_profile_decision')"
              @click="click()"
              @change="change()"
            />
            <h2 v-html="$t('farm_access_edit_date_description')"></h2>
            <div class="inline-dates">
              <DbmDatePicker v-model="farmAccessToChange.validFrom" :dateLabel="$t('farm_access_edit_valid_from')" :required="true" />
              <DbmDatePicker v-model="farmAccessToChange.validUntil" :dateLabel="$t('farm_access_edit_valid_until')" :clearable="true" />
            </div>
            <v-switch
              id="farm_access_edit_term_of_use"
              :rules="[$rules.required]"
              v-model="termOfUseAccepted"
              color="primary"
              :label="$t('farm_access_edit_term_of_use')"
            ></v-switch>
            <div v-if="showAccessReleaseRecall">
              <v-switch
                id="farm_access_release_recall"
                :rules="[$rules.required]"
                v-model="accessReleaseRecall"
                color="primary"
                :label="$t('farm_access_release_recall')"
              ></v-switch>
            </div>
          </v-card-text>
          <div v-if="showWarning" class="releaseWarning">
            <span v-html="$t('farm_access_release_warning')" />
          </div>
          <v-card-actions>
            <v-btn color="primary" @click="closeDialog()">
              <span v-html="$t('farm_access_save_close')" />
            </v-btn>
            <v-btn color="secondary" class="shadow-none" @click="cancel()"><span v-html="$t('cancel')" /></v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>
<script lang="ts">
import { showError } from '@/services/axios'
import { apiURL, emitter } from '../main'
import DbmDatePicker from '@/components/dbmDatePicker.vue'
import { DTSHeaders } from '@/services/BackendService'
import { useFarmAccessStatusesStore } from '@/store/enums/FarmAccessStatusesStore'

import { defineComponent } from 'vue'
export default defineComponent({
  data() {
    return {
      dialog: false,
      ownedFarmAccesses: [],
      loading: false,
      termOfUseAccepted: false,
      accessReleaseRecall: false,
      showAccessReleaseRecall: false,
      showWarning: false,
      previousStatus: '',
      farmAccessToChange: {
        status: '',
        validFrom: '',
        validUntil: ''
      },
      ownedFarmAccessesfields: [
        // Edit
        {
          title: '',
          key: 'edit',
          sortable: false
        },

        // Datenbezüger
        {
          title: this.$t('profile_milk_quality_results_release_table_data_collector'),
          key: 'collector',
          sortable: false
        },
        // Status
        {
          title: this.$t('farm_access_edit_status'),
          key: 'status',
          sortable: false
        },
        // Freigabe erteilt
        {
          title: this.$t('profile_milk_quality_results_release_table_release_date'),
          key: 'datetime',
          sortable: false
        },
        // Bis
        {
          title: this.$t('farm_access_edit_valid_until'),
          key: 'validuntil',
          sortable: false
        },
        // Tierart
        {
          title: this.$t('farm_access_edit_valid_until'),
          key: 'animalType' + this.$getUpLangKey(),
          sortable: false
        }
      ] as DTSHeaders
    }
  },
  props: ['tag'],
  methods: {
    change() {
      if (this.previousStatus == 'APPROVED_BY_FARMER') {
        if (this.previousStatus != this.farmAccessToChange?.status) {
          this.showAccessReleaseRecall = true
        }
      }
      if (this.previousStatus == this.farmAccessToChange?.status) {
        this.showWarning = true
      } else {
        this.showWarning = false
      }
    },
    showStatus(status: string) {
      const translatedStatus = this.farmAccessStatuses.filter(function (element: any) {
        return element.name == status
      })
      return translatedStatus[0][this.$getLangKey()]
    },
    async loadOwnedFarmAccesses() {
      this.loading = true
      try {
        const response = await this.axios.get(apiURL + '/farmAccesses/owned', {
          headers: { Accept: 'application/json' }
        })
        this.ownedFarmAccesses = response.data
      } catch (e) {
        showError(e)
      } finally {
        this.loading = false
      }
    },
    changeFarmAccess(item: any) {
      this.termOfUseAccepted = null
      this.accessReleaseRecall = null
      this.showAccessReleaseRecall = false
      this.farmAccessToChange = item
      this.dialog = true
      this.showWarning = false
      this.previousStatus = this.farmAccessToChange?.status
    },
    checkIfStatusCanBeChanged(status: string) {
      if (status == 'APPROVED_BY_ADMIN' || status == 'APPROVED_BY_SYSTEM') {
        return true
      } else return false
    },
    getInfos() {
      console.log('getInfos')
    },
    async closeDialog() {
      if (this.previousStatus == this.farmAccessToChange?.status) {
        this.showWarning = true
        return false
      }
      if (!(await this.$refs.farmAccessesForm.validate()).valid) {
        return false
      }
      try {
        this.loading = true
        const response = await this.axios.patch(
          apiURL + '/farmAccesses/' + this.farmAccessToChange.id,
          {
            status: this.farmAccessToChange.status,
            validFrom: this.farmAccessToChange.validFrom,
            validUntil: this.farmAccessToChange.validUntil
          },
          { headers: { 'Content-Type': 'application/json' } }
        )
        if (await response) {
          emitter.emit('toastSuccess', this.$t('toast_save_success_message'))
          this.loadOwnedFarmAccesses()
        }
      } catch (e) {
        console.log('e', e)
        showError(e)
      } finally {
        this.loading = false
        this.dialog = false
        this.farmAccessToChange = {
          status: '',
          validFrom: '',
          validUntil: ''
        }
      }
    },
    cancel() {
      this.dialog = false
    }
  },
  components: {
    DbmDatePicker: DbmDatePicker
  },
  computed: {
    farmAccessStatuses() {
      return useFarmAccessStatusesStore().farmAccessStatuses
    },
    limitedFarmAccessStatuses() {
      return useFarmAccessStatusesStore().limitedFarmAccessStatuses
    }
  },
  watch: {},
  beforeMount() {
    this.loadOwnedFarmAccesses()
    useFarmAccessStatusesStore().fetchFarmAccessStatuses()
    useFarmAccessStatusesStore().fetchLimitedFarmAccessStatuses()
  }
})
</script>
<style lang="scss" scoped>
@import '@/styles/variables.scss';

.releaseWarning {
  color: $dbm-error-red;
  margin: $font-xs;
}
</style>
