<template>
  <div>
    <v-container>
      <v-layout row justify-center>
        <v-overlay v-model="loading" persistent fullscreen id="loading-dialog" content-class="loading-dialog">
          <v-container fill-height>
            <v-layout row justify-center align-center>
              <v-progress-circular indeterminate color="secondary"></v-progress-circular>
            </v-layout>
          </v-container>
        </v-overlay>
      </v-layout>
      <v-card>
        <v-tabs v-model="activeTab">
          <!-- -->
          <v-tab value="user"><span v-html="$t('profile_user_tab')" /></v-tab>
          <v-tab value="legalEntity"><span v-html="$t('profile_legal_entity_tab')" /></v-tab>
          <v-tab value="settings" v-if="$getBeta()" class="beta"><span v-html="$t('profile_settings_tab')" /></v-tab>
        </v-tabs>
        <v-card-text>
          <v-tabs-window v-model="activeTab">
            <v-tabs-window-item value="user">
              <user v-if="currentUserData?.id" v-model="currentUserData"></user>
            </v-tabs-window-item>

            <v-tabs-window-item value="legalEntity">
              <legal-entity></legal-entity>
            </v-tabs-window-item>

            <v-tabs-window-item value="settings" v-if="$getBeta()" class="beta">
              <settings :emailNotificationIssues="emailNotificationIssues"></settings>
            </v-tabs-window-item>
          </v-tabs-window>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>
<script lang="ts">
import { showError } from '../../services/axios'
import { apiURL, emitter } from '@/main'
import { mapGetters } from 'vuex'
import { useLegalEntityStore } from '@/store/LegalEntityStore'
import _ from 'lodash'
import user from '@/views/profile/user.vue'
import legalEntity from '@/views/profile/legalEntity.vue'
import settings from '@/views/profile/settings.vue'
import { format, addDays } from 'date-fns'

const defaultData = [
  {
    name: '',
    password: '',
    lastLogin: undefined
  }
]

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'profile',
  props: { tab: { type: String, required: false, default: 'user' } },
  data() {
    return {
      loading: false,
      model: defaultData,
      currentUserData: {},
      emailNotificationIssues: true,
      applicationUser: '',
      legacyLegalEntities: []
    }
  },
  computed: {
    ...mapGetters('oidcStore', ['oidcIsAuthenticated', 'oidcUser', 'oidcAuthenticationIsChecked', 'oidcScopes']),
    ...mapGetters('SettingsStore', ['OIDC_config']),
    applicationUserPerson() {
      return this?.$store?.state?.PersonsStore.applicationUser
    },
    domainId() {
      const legalEntityStore = useLegalEntityStore()
      return legalEntityStore.getSelectedPersonId
    },
    activeTab: {
      get() {
        return this.tab
      },
      set(tabName: string): void {
        this.$router
          .push({
            name: 'profile',
            params: { tab: tabName }
          })
          .catch((e) => {
            /* duplicated route */
          })
      }
    }
  },
  components: {
    user: user,
    legalEntity: legalEntity,
    settings: settings
  },
  methods: {
    async deleteLegalEntityRelation(item) {
      this.loading = true
      try {
        const result = await this.axios.patch(
          apiURL + 'applicationUsers/' + item.id + '/applicationUserLegalEntities/',
          {
            id: item.id,
            validFrom: format(addDays(new Date(), -2), 'yyyy-MM-dd'),
            validUntil: format(addDays(new Date(), -1), 'yyyy-MM-dd')
          },
          { headers: { 'Content-Type': 'application/json' } }
        )
        if (await result) {
          emitter.emit('toastSuccess', this.$t('toast_save_success_message'))
        }
      } catch (e) {
        showError(e)
      } finally {
        this.loading = false
      }
    },
    async eMailNotificationIssues(item) {
      try {
        const result = await this.axios.patch(
          apiURL + `/applicationUsers/` + this.applicationUserPerson.id,
          {
            emailNotificationIssues: this.emailNotificationIssues
          },
          { headers: { 'Content-Type': 'application/json' } }
        )
      } catch (e) {
        showError(e)
      }
    }
  },
  watch: {
    emailNotificationIssues(newValue) {
      this.eMailNotificationIssues(newValue)
    }
  },
  async mounted() {
    const checkInUser = await this.axios.put(apiURL + '/applicationUsers/checkIn/')
    this.currentUserData = checkInUser
  }
})
</script>
