<template>
  <v-container grid-list-xl>
    <v-row>
      <v-col cols="12" sm="12" md="6" lg="6">
        <v-row v-if="legalEntityAddress?.language?.id">
          <!-- Mandant Zentralschweizer Milchproduzenten -->
          <h2 v-html="$t('profile_legal_entity_title')"></h2>
          <!-- Text Erklärung -->
          <p v-html="$t('profile_legal_entity_text')"></p>
          <!-- Rechtliche Einheit (Korrespondenzadresse) -->
          <h2 v-html="$t('profile_legal_entity_address')"></h2>
          <address-preview v-bind:address="legalEntityAddress" :showPhoneNumber="true" :hideTitle="true"></address-preview>

          <!-- Sprache -->
          <v-select
            disabled
            id="profile_agate_language"
            v-model="legalEntityAddress.language.id"
            :label="$t('profile_agate_language')"
            :items="languages"
            :item-title="this.$getLangKey()"
            item-value="id"
            clearable
          />

          <h2 v-html="$t('profile_legal_entity_locality_address')"></h2>
          <div v-for="dairy in dairies" v-bind:key="dairy.id">
            <!-- Standort (Korrespondenzadresse) -->
            <v-textarea
              disabled
              filled
              rows="5"
              :label="$t('profile_legal_entity_locality_address')"
              id="profile_legal_entity_locality_address"
              v-model="dairy.formattedAddress"
            ></v-textarea>

            <!-- Sprache -->
            <v-select
              disabled
              id="profile_agate_language"
              v-model="dairy.languageId"
              :label="$t('profile_agate_language')"
              :items="languages"
              :item-title="this.$getLangKey()"
              item-value="id"
              clearable
            />
          </div>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script lang="ts">
import { showError } from '@/services/axios'
import { useLanguagesStore } from '@/store/enums/LanguagesStore'
import { useLegalEntityStore } from '@/store/LegalEntityStore'
import addressPreview from '@/views/masterdata/addressPreview.vue'
import { apiURL, emitter } from '@/main'
import { DTSHeaders, DTSHeader } from '@/services/BackendService'
import { BaseAddress } from '@/views/contacts/contact'

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'legalEntityProfile',
  components: { addressPreview: addressPreview },
  data() {
    return {
      fields: <DTSHeader[]>[
        {
          title: this.$t('profile_existing_user_name_legacy'),
          key: 'name',
          sortable: false
        },
        {
          title: this.$t('Rechtl. Person'),
          key: 'legalEntityAddressName',
          sortable: false
        }
      ],
      legalEntityAddress: new BaseAddress(),
      dairies: []
    }
  },
  computed: {
    domainId() {
      const legalEntityStore = useLegalEntityStore()
      return legalEntityStore.getSelectedPersonId
    },
    languages() {
      useLanguagesStore().languages
    }
  },
  methods: {
    async loadDairies() {
      try {
        const dairies = await this.axios.get(apiURL + '/legalEntities/' + this.domainId + '/dairies')
        if (await dairies.data.length) {
          this.dairies = dairies.data
        }
      } catch (e) {
        this.dairies = []
        showError(e)
      }
    },
    async loadLegalEntityDefaultAddress() {
      try {
        const response = await this.axios.get(apiURL + '/legalEntities/' + this.domainId + '' + '/defaultAddress', { headers: { Accept: 'application/json' } })
        this.legalEntityAddress = response.data
      } catch (e) {
        this.legalEntityAddress = new BaseAddress()
        showError(e)
      }
    },
    async setMilkQualityContentAutoOrder(dairy) {
      dairy.loadingMilkQualityContent = true

      console.log('setMilkQualityContentAutoOrder', dairy)
      try {
        const response = await this.axios.patch(
          apiURL + '/dairies/' + dairy.id + '/setMilkQualityContentAutoOrder',
          { hasMilkQualityContentAutoOrder: dairy.hasMilkQualityContentAutoOrder },
          { headers: { Accept: 'application/json' } }
        )
        dairy.hasMilkQualityContentAutoOrder = response.data.hasMilkQualityContentAutoOrder
        emitter.emit('toastSuccess', { message: this.$t('toast_save_success_message') })
      } catch (e) {
        this.legalEntityAddress = []
        showError(e)
      } finally {
        dairy.loadingMilkQualityContent = false
      }
    }
  },
  async mounted() {
    await this.loadLegalEntityDefaultAddress()
    await this.loadDairies()
  }
})
</script>
